<template>
    <div
        class="h-480px flex w-full gap-24px px-120px mt-72px ipad:(min-h-831px px-24px gap-36px pt-48px flex-col px-24px) mobile:(min-h-720px flex-col py-0 px-16px mt-64px)"
    >
        <div
            class="right__content w-690px flex items-start ipad:(flex w-full static justify-center) mobile:( static w-full justify-center)"
        >
            <div
                class="w-full mt-113px ipad:(flex flex-col items-center mt-48px) mobile:(flex mt-32px flex-col items-center)"
            >
                <div class="section_headline mobile:(leading-48px text-base-40px text-center)">
                    Crypto On & Off Ramp
                </div>
                <div
                    class="desc mt-24px mr-40px mb-64px ipad:(text-center mx-10px mb-40px) mobile:(mb-24px mr-0 text-center tracking-0.5px leading-28px)"
                >
                    {{ banner.desc }}
                </div>
                <div class="flex">
                    <button class="btn" @click="goToServices">{{ banner.ourService }}</button>
                </div>
            </div>
        </div>
        <div
            class="left__content flex overflow-hidden w-430px ipad:(w-full h-490px justify-center items-center) mobile:(w-full justify-center justify-center items-center h-496px)"
        >
            <img
                class="big__img -z-1 flex-1 ipad:hidden mobile:hidden"
                src="../../../img/trading.svg"
            />
            <img class="hidden ipad:block mobile:(block)" src="../../../img/trading.svg" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'banner',
    props: {
        banner: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {}
    },
    methods: {
        goToServices() {
            // will scroll to the selected section
            document.getElementById('Services').scrollIntoView()
        }
    }
}
</script>
<style>
.right__content {
    background-image: url('../../../img/Ellipse_Bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90% 95%;
    background-position-x: 20px;
    background-position-y: -56px;
}
.big__img {
    transform: scale(1.5);
}
@media screen and (min-width: 768px) and (max-width: 1019px) {
    .right__content {
        background-size: 70% 62%;
        background-position-x: 122px;
        background-position-y: 22px;
    }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
    .right__content {
        background-position-y: -36px;
    }
}
</style>
